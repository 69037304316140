/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'train-freight-front': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M5.065.158A1.5 1.5 0 015.736 0h4.528a1.5 1.5 0 01.67.158l3.237 1.618a1.5 1.5 0 01.83 1.342V13.5a2.5 2.5 0 01-2.5 2.5h-9A2.5 2.5 0 011 13.5V3.118a1.5 1.5 0 01.828-1.342zM2 9.372V13.5A1.5 1.5 0 003.5 15h4V8h-.853a.5.5 0 00-.144.021zM8.5 15h4a1.5 1.5 0 001.5-1.5V9.372l-4.503-1.35A.5.5 0 009.353 8H8.5zM14 8.328v-5.21a.5.5 0 00-.276-.447l-3.236-1.618A.5.5 0 0010.264 1H5.736a.5.5 0 00-.223.053L2.277 2.67A.5.5 0 002 3.118v5.21l1-.3V5a1 1 0 011-1h8a1 1 0 011 1v3.028zm-2-.6V5H8.5v2h.853a1.5 1.5 0 01.431.063zM7.5 7V5H4v2.728l2.216-.665A1.5 1.5 0 016.646 7zm-1-5a.5.5 0 000 1h3a.5.5 0 000-1zm-3 8a.5.5 0 100 1 .5.5 0 000-1m9 0a.5.5 0 100 1 .5.5 0 000-1M5 13a1 1 0 11-2 0 1 1 0 012 0m7 1a1 1 0 100-2 1 1 0 000 2"/>',
    },
});
